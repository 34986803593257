import type { Carrier } from './carrier.ts';

export enum PolicyType {
  Home = 'home',
  Auto = 'auto',
}

export enum InsurableInterest {
  RealProperty = 'real_property',
  Vehicle = 'vehicle',
}

export enum CoverageKey {
  Dwelling = 'dwelling',
  OtherStructures = 'other_structures',
  PersonalProperty = 'personal_property',
  LossOfUse = 'loss_of_use',
  PersonalLiability = 'personal_liability',
  MedicalPayments = 'medical_payments',

  Hurricane = 'hurricane',
  WindHail = 'wind_hail',
  WaterBackup = 'water_backup',
  Earthquake = 'earthquake',
  Flood = 'flood',
  ExtendedReplacementCost = 'extended_replacement_cost',
  ReplacementCostOnContents = 'replacement_cost_on_contents',
  ServiceLine = 'service_line',
  EquipmentBreakdown = 'equipment_breakdown',
  Foundation = 'foundation',
  FunctionalReplacementCost = 'functional_replacement_cost',

  BiPerPerson = 'bodily_injury_per_person',
  BiPerAccident = 'bodily_injury_per_accident',
  PropertyDamage = 'property_damage',
  UninsuredMpd = 'uninsured_motorist_property_damage',
  UninsuredMbiPerPerson = 'uninsured_motorist_bodily_injury_per_person',
  UninsuredMbiPerAccident = 'uninsured_motorist_bodily_injury_per_accident',
  UnderinsuredMbiPerPerson = 'underinsured_motorist_bodily_injury_per_person',
  UnderinsuredMbiPerAccident = 'underinsured_motorist_bodily_injury_per_accident',
  Comprehensive = 'comprehensive',
  Collision = 'collision',
  Towing = 'towing',
  FullGlass = 'full_glass',
  RentalPerDay = 'rental_per_day',
  RentalMaxAmount = 'rental_max_amount',
  PersonalInjuryProtection = 'personal_injury_protection',
}

export interface QuoteCoverage {
  key: CoverageKey;
  title: string;
  description: string;
  asset_gid: string;
  limit_type: LimitType;
  limit_value: number | null;
  limit_description: string;
  deductible_type: DeductibleType;
  deductible_value: number | null;
  deductible_description: string | null;
}

export interface QuoteCarrier extends Carrier {
  description: string;
}

interface PaymentOption {
  term_months?: number | null;
  bundle?: boolean | null;
  downpayment?: number | null;
  subsequent_payment?: number | null;
  full_term_premium?: number | null;
}

export enum QuoteKind {
  Rc1 = 'rc1',
  Rc2 = 'rc2',
  NonIntegrated = 'non_integrated',
}

export enum LimitType {
  MoneyLimit = 'money_limit',
  MonthlyLimit = 'monthly',
  OtherLimit = 'other',
}

export enum DeductibleType {
  Flat = 'flat',
  Percentage = 'percentage',
}

export interface Quote {
  gid: string;
  created_at: string;
  kind: QuoteKind;
  status: string;
  state: string;
  premium: number;
  bundled_premium: number | null;
  carrier: QuoteCarrier;
  assets_gids: string[] | null;
  message: string | null;
  policy_type: PolicyType;
  requirement: string;
  insurable_interest: InsurableInterest;
  mpd_discount: number | null;
  coverages: QuoteCoverage[] | null;
  coinsureds_gids: string[] | null;
  effective_date: string;
  payment_options: PaymentOption[] | null;
  bundle?: boolean | null; // is bundle with same carrier
  multi_policy_discount_requested?: boolean | null;
  order?: number;
  appointed: boolean;
}

export interface SuggestedQuoteResponse {
  quote: Quote | null;
  auto_quote?: {
    // TODO: Refactor this after MVP. Maybe use a group pattern.
    quote: Quote;
  };
}

export enum QuotingStatus {
  Pending = 'pending',
  Finished = 'finished',
}

export interface QuotesResponse {
  quotes: Quote[];
  quotes_request_status: QuotingStatus;
}
